<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 12 9"
  >
    <path
      fill="currentColor"
      d="M4 9V8h4v1H4ZM2 4h8v1H2V4Zm10-4v1H0V0h12Z"
    />
  </svg>
</template>
